<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">{{ $t('MAIN_MENU.USERS') }}</span>
        </h3>
        <div class="d-flex space-2">
          <div class="card-toolbar">
            <button
              @click="exportAsExcel"
              class="btn btn-light-primary font-weight-bold px-10"
            >
              Export .xlsx
            </button>
          </div>
          <div class="card-toolbar">
            <router-link
              v-if="
                currentUserRole === 'ROLE_ROOT' ||
                currentUserRole === 'ROLE_SUPER_ADMIN'
              "
              to="/users/new"
              class="btn btn-primary font-weight-bolder ml-4"
            >
              <span class="svg-icon svg-icon-md">
                <inline-svg src="media/svg/icons/General/User.svg" /> </span
              >Add New User
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="!isLoaded" class="col-12 text-center my-5 p-2 pt-5 bg-secondary">
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
        </div>
      </div>
      <div v-else-if="isLoaded && users.length > 0"  class="card-body pt-0">
        <div class="table-responsive mb-0 pb-0">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th style="min-width: 120px" class="pl-6">
                  Email, Name & Surname
                </th>
                <th style="min-width: 120px">Role</th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_ROOT' ||
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    currentUserRole === 'ROLE_ADMIN'
                  "
                  class="pr-6"
                  style="min-width: 160px; text-align: right"
                >
                  {{ $t('GENERAL.ACTIONS') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, key) in users" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    v-if="
                      currentUserRole === 'ROLE_ROOT' ||
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      currentUserRole === 'ROLE_ADMIN'
                    "
                    :to="'/users/edit/' + user.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ user.email }}</router-link
                  >
                  <span
                    v-else
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ user.email }}</span
                  >
                  <div>
                    <span class="font-weight-bolder">Ime: </span>
                    <span class="text-muted font-weight-bold text-hover-primary"
                      >{{ user.name }}
                    </span>
                    <span
                      class="text-muted font-weight-bold text-hover-primary"
                      >{{ user.surname }}</span
                    >
                  </div>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span
                    class="label label-lg label-light-primary label-inline mr-2"
                    >{{ user.roles[0] }}</span
                  >
                </td>
                <td
                  v-if="
                    currentUserRole === 'ROLE_ROOT' ||
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    currentUserRole === 'ROLE_ADMIN'
                  "
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                   
                >
                  <router-link
                    v-if="
                      currentUserRole === 'ROLE_ROOT' ||
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      currentUserRole === 'ROLE_ADMIN'
                    "
                    :to="'/users/edit/' + user.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                    v-b-tooltip.hover.top="$t('USERS.EDIT_USER')"
                  >
                    <span class="svg-icon svg-icon-md" >
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <a
                    v-if="
                      currentUserRole === 'ROLE_ROOT' ||
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      currentUserRole === 'ROLE_ADMIN'
                    "
                    @click="deleteEntity(user.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                    v-b-tooltip.hover.top="$t('USERS.DELETE_USER')"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <span v-else-if="isLoaded && users.length === 0" class="text-muted font-weight-500">No users</span>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import * as XLSX from 'xlsx';

export default {
  name: 'ListUsers',
  data() {
    return {
      users: [],
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('MAIN_MENU.USERS'), route: '' }])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('users', '?isLoggerUser=false').then(({ data }) => {
        this.users = [...data['hydra:member']]
        this.isLoaded = true
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'users',
        `?isLoggerUser=false&company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}`
      ).then(({ data }) => {
        this.users = [...data['hydra:member']]
        this.isLoaded = true
      })
    }
  },
  methods: {
    deleteEntity(id) {
      let confirmation = confirm('Zelite izbrisati ovog korisnika?')
      if (confirmation === false) return

      this.isLoaded = false

      ApiService.delete(`users/${id}`).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'User successfully deleted',
        })

        this.isLoaded = true
      })
    },
    exportAsExcel() {
     const exportData = this.users.map(user => 
      ({name: user.name, 
        surname: user.surname, 
        email: user.email, 
        phone: user.phone, 
        roles: user.roles.join(", ")})
      )
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'users.xlsx');
    },
  },
}
</script>

<style scoped lang="scss"></style>
